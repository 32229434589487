
























import { Component, Prop, PropSync, Vue } from "vue-property-decorator";

@Component
export default class HelloDialog extends Vue {
  @PropSync("visible", { type: Boolean }) syncedVisible!: boolean;
  @Prop() name!: string;

  hide() {
    this.syncedVisible = false;
  }

  onTouchMove() {
    this.hide();
  }

  onWheel() {
    this.hide();
  }
}
