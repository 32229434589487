















import { Component, Vue, Watch } from "vue-property-decorator";
import HelloDialog from "./components/HelloDialog.vue";
import BottomPanel from "./components/BottomPanel.vue";

@Component({
  components: {
    HelloDialog,
    BottomPanel,
  },
})
export default class App extends Vue {
  isHelloDialogVisible = false;
  get username() {
    return process.env.VUE_APP_USERNAME;
  }

  @Watch("username", { immediate: true })
  onUserNameChanged(username: string) {
    document.title = username;
  }

  updateHelloDialogVisible(value: boolean) {
    this.isHelloDialogVisible = value;
  }

  mounted() {
    this.isHelloDialogVisible = true;
  }
}
